import { NextPage } from 'next'
import Layout from 'components/Layout'
import { useEffect, useState } from 'react'
import { Head } from 'components/Head'
import { PuddingHome } from 'components/landing/pudding/PuddingHome'
import { API } from 'aws-amplify'


const IndexPage: NextPage<any> = ({}) => {
  const [marketplaceConfigs, setMarketplaceConfigs] = useState<any>({})

  useEffect(() => {
    API.get(
      'mobo-marketplace',
      `/api/mobo/marketplaceConfigs/getMarketplaceConfigs`,
      {}
    ).then(marketplaceConfigs => {
      setMarketplaceConfigs(marketplaceConfigs)
    })
  }, [])

  return (
    <Layout>
      <Head />
      {marketplaceConfigs?.type === 'pudding' ? (
        <PuddingHome marketplaceConfigs={marketplaceConfigs} />
      ) : (<></>)}
    </Layout>
  )
}

export default IndexPage
