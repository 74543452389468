import { Flex, Text } from 'components/primitives'
import { styled } from 'stitches.config'
import { FC } from 'react'

export const DigitalRenaissanceHero: FC<any> = ({}) => {
  const DottedText = styled('span', {
    fontFamily: '$pixelifySans',
    fontWeight: '600',
    letterSpacing: '0.1em',
    color: '#000000',
  })

  return (
    <Flex
      css={{
        backgroundImage: 'url(/img/hero/renaissance-hero-bg.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        minHeight: '556px',
        padding: '0 5%',
        '@md': {
          padding: '0 10%',
        },
        '@lg': {
          padding: '0 20%',
        },
      }}
    >
      <Flex
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '0',
          '@md': {
            marginRight: '2%',
          },
        }}
      >
        <Flex
          direction="column"
          css={{
            alignItems: "start",
            maxWidth: '880px',
            margin: '5% 0',
          }}
        >
          <Text
           css={{
            fontSize: '65px',
            fontWeight: 'bold',
            color: '#ffffff',
            marginBottom: '20px',
            lineHeight: '75px'
           }}
          >
            <span style={{color:'#000000'}}>Discover and Collect the</span> Digital Renaissance
          </Text>
          <Text
           css={{
            fontSize: '30px',
            color: '#ffffff',
            mt: '40px',
            mb: '20px',
            lineHeight: '40px',
            display: 'none',
            '@md': {
              display: 'block'
            }
           }}
          >
            The marketplace from the <DottedText>Moonbirds</DottedText> community where artists are paid 100% of royalties
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}