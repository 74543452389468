import { Flex, Text } from 'components/primitives'
import { FC } from 'react'
import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { ArtistTile } from './ArtistTile'
import { Grid } from 'components/primitives/Grid'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { DigitalRenaissanceHero } from './heros/digitalRenaissanceHero/DigitalRenaissanceHero'
//import { FeaturedCollectionsHero } from './heros/featuredCollectionsHero/FeaturedCollectionsHero'

type Props = {
  marketplaceConfigs: any
}

export const PuddingHome: FC<Props> = ({
  marketplaceConfigs,
}) => {
  const router = useRouter()
  const [artists, setArtists] = useState<any>()

  useEffect(() => {
    API.get(
      'mobo-marketplace',
      `/api/mobo/marketplaceConfigs/getMarketplaceArtists`,
      {}
    ).then(marketplaceArtists => {
      // Randomly choose 12 artists out of all marketplace artists
      setArtists(marketplaceArtists.sort(() => 0.5 - Math.random()).slice(0,12))
    })
  }, [])

  return (
    <Flex
      direction="column"
      css={{
        width: '100%',
      }}
    >
      <DigitalRenaissanceHero />
      <Flex
        direction="column"
        css={{
          margin: '5% 0',
          px: '10px',
          '@sm':{
            px:'30px',
          },
          '@md': {
            margin: '5% auto',
          },
        }}
      >
        {artists && artists.length > 0 && (
          <>
            <Text css={{fontSize:'32px', fontWeight:'bold'}}>Artists</Text>
            <Flex css={{
              justifyContent:'space-between',
              alignItems:'center',
              width:'100%',
              marginBottom:'20px'
            }}>
              <Text css={{fontSize:'18px', color:'#fffcfcb5'}}>Discover your next favorite artist.</Text>
              <Link href={`/${router.query.chain}/explore?queryBy=Artist`}>
                <Text css={{
                  fontSize:'18px',
                  fontWeight:'bold',
                  display:'none',
                  '@sm': {
                    display:'flex'
                  }
                }}>
                  SEE ALL
                </Text>
              </Link>
            </Flex>
            <Grid
              css={{
                gap: '$4',
                pb: '$6',
                mt: '$5',
                gridTemplateColumns: '1fr',
                '@md': {
                  gridTemplateColumns: '1fr 1fr',
                },
                '@lg': {
                  gridTemplateColumns: '1fr 1fr 1fr',
                },
              }}
            >
              {artists?.map((artist: any) => (
                <ArtistTile artist={artist} href={`/${router.query.chain}/${artist.user.username}`} key={artist.user.username}/>
              ))}
            </Grid>
          </>
        )}
      </Flex>
    </Flex>
  )
}