import { Flex, Text, FormatCryptoCurrency } from 'components/primitives'
import { FC } from 'react'
import Link from 'next/link'

type Props = {
  artist: any
  href: string
}

export const ArtistTile: FC<Props> = ({
  artist,
  href,
}) => {
  return (
    <Link 
      href={href}>
        <Flex
          direction="column"
          css={{
            backgroundColor: '#1D1E29',
            borderRadius: '10px',
            margin: '10px 0',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            padding: '15px',
          }}
        >
          <Flex
            css={{
              margin: '20px auto',
              width: '180px',
              height: '180px',
            }}
          >
            <img 
              src={`${artist.user.profile_image_url || '/img/img-thumbnail.svg'}`}
              style={{objectFit:'cover'}}
              width={180}
            />
          </Flex>
          <Flex css={{margin:'0 auto'}}>
            <Text
              css={{
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              {artist.user.display_name}
            </Text>
          </Flex>
          <Flex direction="column" css={{margin:'0 auto'}}>
            <Flex css={{margin:'10px 0'}}>
              <Flex css={{alignItems:'center', padding:'5px 10px', width: '150px', borderRadius:'5px', backgroundColor: 'rgb(255 255 255/0.07)', marginRight:'10px'}}>
                <Text 
                  css={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  {artist.stats?.ownerCount || 0} <span style={{opacity:'0.65'}}>owners</span>
                </Text>
              </Flex>
              <Flex css={{alignItems:'center', padding:'5px 10px', width: '150px', borderRadius:'5px', backgroundColor: 'rgb(255 255 255/0.07)',}}>
                <Text 
                  css={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  {artist.stats?.count || 0} <span style={{opacity:'0.65'}}>items</span>
                </Text>
              </Flex>
            </Flex>
            <Flex>
              <Flex css={{alignItems:'center', padding:'5px 10px', width: '150px', borderRadius:'5px', backgroundColor: 'rgb(255 255 255/0.07)', marginRight:'10px'}}>
              <Flex>
                  <FormatCryptoCurrency
                    amount={artist.stats?.allTime || '0'}
                    logoHeight={14}
                    textStyle={'smallStats'}
                    maximumFractionDigits={2}
                  />
                  <Text
                    css={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      opacity: '0.65',
                      marginLeft: '5px',
                    }}
                  >
                    volume
                  </Text>
                </Flex>
              </Flex>
              <Flex css={{alignItems:'center', padding:'5px 10px', width: '150px', borderRadius:'5px', backgroundColor: 'rgb(255 255 255/0.07)',}}>
                <Flex>
                  <FormatCryptoCurrency
                    amount={artist.stats?.floor || '0'}
                    logoHeight={14}
                    textStyle={'smallStats'}
                    maximumFractionDigits={2}
                  />
                  <Text
                    css={{
                      textAlign: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      opacity: '0.65',
                      marginLeft: '5px',
                    }}
                  >
                    floor
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
    </Link>
  )
}